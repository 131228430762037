import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  CustomerCollectionPanel,
  Button,
  ExternalLink,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import _ from 'lodash';
import {
  deleteBookingItem,
  setCollectFromHome,
  setBookingData,
} from '../CustomerPage/CustomerPage.duck.js';
import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues as setInitialValuesFunc,
  setEnquiryListingId,
} from './ListingPage.duck';

import { get } from 'lodash';

import {
  setInitialValues as setInitialValuesCheckout,
  setPrice,
} from '../CheckoutPage/CheckoutPage.duck';
import BookingBreakdown from './BookingBreakdown/BookingBreakdown';

import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionAbout from './SectionAbout';
import ContinueButton from './ContinueButton';
import css from './ListingPage.module.css';
import CollectionDate from './CollectionDate';
import { awsLogger } from '../../util/awsLoggin';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

// component for listing page

export class ListingPageComponent extends Component {
  constructor(props) {
  
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      CollectionDate: new Date(),
      completedBy: null,
      imageCarouselOpen: false,
      enquiryModalOpen: false,
      bookingDetails: null,
      serviceKey: '',
      makers: [],
      preferences: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  componentDidMount() {
    if (window) {
      const bookingData = JSON.parse(localStorage.getItem('bookingData'));
      const { currentUser } = this.props;
      const email = get(currentUser, 'attributes.email') ? currentUser.attributes.email : '';
      const Obj = {
        category: "info",
        details: "bookingData",
        type: 'ListingPage',
        email: email,
        data: bookingData
      }
      awsLogger('listingPage','info',JSON.stringify(Obj));
      if (bookingData) {
        this.setState({
          bookingDetails: bookingData,
          serviceKey: bookingData.serviceKey,
        });
      }
    }
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      onSetInitialValuesCheckout,
      onSetEnquiryListingId,
      enquiryModalOpenForListingId,
      collectFromHome,
      setPrice,
    } = this.props;

   

    const { bookingDetails, CollectionDate, completedBy } = this.state;
    
    // FOR ALTERATION AND RESTORATION
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    // add calculated price.
    if (bookingDetails.totalPrice) {
      listing.attributes.price = new Money(bookingDetails.totalPrice, config.currency);
    }

    //bookingDates.endDate,
    const initialValues = {
      price: bookingDetails.totalPrice || null,
      listing,
      bookingData: Object.assign({}, bookingDetails, {
        price: bookingDetails.totalPrice || null,
        collectFromHome: collectFromHome,
        completedBy: completedBy || 'flexible',
      }),
      bookingDates: {
        bookingStart: moment().format('YYYY-MM-DD'),
        bookingEnd: CollectionDate,
      },
      confirmPaymentError: null,
    };

    onSetInitialValuesCheckout(initialValues);

    // set enquiry listing id for MADE TO MEASURE
    if (bookingDetails.serviceKey === 'madeToMeasure' || bookingDetails.serviceKey === 'Repair') {
      if (enquiryModalOpenForListingId === null) {
        onSetEnquiryListingId(params.id);
      }
      this.setState({ enquiryModalOpen: true });
    } else {
      const saveToSessionStorage = !this.props.currentUser;

      const routes = routeConfiguration();
      // Customize checkout page state with current listing and selected bookingDates
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

      callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();
      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
          {}
        )
      );
    }
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  getCollectionDate = date => {
    this.setState({ CollectionDate: date });
  };

  setCompletedBy = value => {
    this.setState({ completedBy: value });
  };

  setPreferences = value => {
    this.setState({ preferences: value });
  };

  changeRoute = () => {
    const { history } = this.props;
    history.push('/CustomerPage/maker');
  };
  goToLanding = () => {
    const { history } = this.props;
    history.push('/');
  };

  changeBookingData = value => {
    const { setCollectFromHome } = this.props;
    let newBookingDetails = this.state.bookingDetails;
    if (newBookingDetails.serviceKey != 'madeToMeasure' || newBookingDetails.serviceKey != 'Repair') {
      if (newBookingDetails.collectFromHome) {
        if (value === 'HomeCollection') {
          newBookingDetails.totalPrice = this.state.bookingDetails.totalPrice;
        } else {
          setCollectFromHome(false);
          newBookingDetails.collectFromHome = false;
          newBookingDetails.totalPrice = this.state.bookingDetails.totalPrice - Number(8);
        }
      } else {
        if (value === 'HomeCollection') {
          setCollectFromHome(true);
          newBookingDetails.collectFromHome = true;
          newBookingDetails.totalPrice = this.state.bookingDetails.totalPrice + Number(8);
        }
      }
      this.setState({
        bookingDetails: newBookingDetails,
      });
    }
  };

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      bookingData,
      deleteBookingItem,
      collectFromHome,
      setCollectFromHome,
      setPrice,
    } = this.props;
    let userType = 'maker';
    if (currentUser != null) {
       userType = currentUser.attributes.profile.protectedData.userType ? currentUser.attributes.profile.protectedData.userType : 'maker';
    }
    const { serviceKey } = this.state;

    let buttonText = (serviceKey === 'madeToMeasure' || serviceKey === 'Repair') ? 'Contact Maker' : 'Next: Payment';

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;
    let accurateDistance = false;

    if (typeof localstorage !== 'undefined') {
      const { lat, lng } = JSON.parse(localStorage.getItem('CusLocation:'));
      let cusLngLat = new google.maps.LatLng(lng, lat);
      const mkrLoc = currentListing.attributes.geolocation || false;
      const makerLatlong = new google.maps.LatLng(mkrLoc.lng, mkrLoc.lat);
      const distance = mkrLoc
        ? google.maps.geometry.spherical.computeDistanceBetween(makerLatlong, cusLngLat)
        : false;
      accurateDistance = distance ? ((distance / 1000) * 0.621371).toFixed(2) : false;
    }

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );
    const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const topbar = <TopbarContainer userType={userType} />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      e.preventDefault();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const BackToMakerList = () => {
      return (
        <NamedLink
          className={css.authorNameLink}
          name="ListingPage"
          params={params}
          to={{ hash: '#host' }}
        >
          Back to Maker List
        </NamedLink>
      );
    };

    const amenityOptions = findOptionsForSelectFilter('specialistSkills', filterConfig);
    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div className={css.contentContainer}>
                <SectionAvatar user={currentAuthor} params={params} />
                <div className={css.mainContent}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    user={currentAuthor}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    category={category}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    accurateDistance={accurateDistance}
                  />
                  <SectionDescriptionMaybe description={description} />
                  <SectionAbout options={amenityOptions} publicData={publicData} />
                  <SectionFeaturesMaybe options={amenityOptions} publicData={publicData} />
                  <SectionRulesMaybe publicData={publicData} />
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                  <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                  <SectionHostMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>

                {/* <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                /> */}
                {userType == 'customer' && !_.isEmpty(this.state.bookingDetails) && (
                  <div>
                    <div className={css.bookingPanel}>
                      <CollectionDate
                        onSubmit={this.handleSubmit}
                        getCollectionDate={this.getCollectionDate}
                        setCompletedBy={this.setCompletedBy}
                        buttonText={buttonText}
                        bookingDetails={this.state.bookingDetails}
                        bookingData={this.state.bookingDetails}
                        collectFromHome={collectFromHome}
                        setPreferences={this.setPreferences}
                        serviceKey={this.state.serviceKey}
                        setCollectFromHome={setCollectFromHome}
                        setPrice={setPrice}
                        changeBookingData={this.changeBookingData}
                        currentListing={currentListing}
                      />
                      <br />
                      <div style={{ textAlign: 'center' }}>
                        <button className={css.backButton} onClick={this.changeRoute}>
                          Go back to list of Makers
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {(currentUser === null || !_.isEmpty(this.state.bookingDetails)) &&
                  get(currentAuthor, 'id.uuid') !== get(currentUser, 'id.uuid') && (
                    <div>
                      <div className={css.bookingPanel}>
                        {/* <div style={{ textAlign: 'center'  }}>
                        <p>
                         
                        </p>
                        <button className={css.backButton} onClick={this.goToLanding}>
                          
                        </button>
                      </div> */}

                      {
                        userType === 'maker' && 
                        <p>
                        If you would like to book a project with {currentAuthor.attributes.profile.displayName}, please signup or login to your account.  
                        {/* <u style={{ fontWeight: 'bold' }}>Support@theseam.uk</u>{' '} */}
                      </p>
                      }
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { collectFromHome, bookingData } = state.CustomerPage;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    collectFromHome,
    bookingData,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValuesFunc(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onSetInitialValuesCheckout: data => dispatch(setInitialValuesCheckout(data)),
  onSetEnquiryListingId: data => dispatch(setEnquiryListingId(data)),
  deleteBookingItem: item => dispatch(deleteBookingItem(item)),
  setCollectFromHome: bool => dispatch(setCollectFromHome(bool)),
  setPrice: payload => dispatch(setPrice(payload)),
  setBookingData: data => dispatch(setBookingData(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
