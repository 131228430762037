import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionAbout = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  //   const selectedOptions = publicData && publicData.amenities ? publicData.amenities : []
  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.aboutTitle" />
      </h2>
      
      {props.publicData.about != null && props.publicData.about &&  
       <p>{props.publicData.about}</p>
      }
      {/* <PropertyGroup
        id="ListingPage.amenities"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={true}
      /> */}
    </div>
  );
};

export default SectionAbout;
