import React, { Component } from 'react';
import css from './ListingPage.module.css';
import config from '../../config';
import _ from 'lodash';
import moment from 'moment';
import BookingBreakdown from './BookingBreakdown/BookingBreakdown';
import image from '../../forms/CustomerServiceForm/BookingBreakdown/files/infoIcon.png';
import { FieldRadioButton } from '../../components';


export default class CollectionDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      completedBy: `I'm flexible`,
      disabled: true,
      showError: false,
      showDateError: false,
      showCompletedByError: false,
      completedByError: 'This field is required',
      noDateSelect: 'Please enter date in YYYY-MM-DD format',
      preferences: '',
      preferencesError: 'This field is required',
      showPreferencesError: false,
      showHomeCollection: false,
      hideOptions: true
    }
  }
 

handleChanged = e => {
  const { getCollectionDate } = this.props;
  this.setState({
    showDateError: false,
  });
  const d = e.target.value;
  const date = d === moment().format('YYYY-MM-DD') ? moment().add(1, 'days').format('YYYY-MM-DD') : d;
  if (moment().format('YYYY-MM-DD') > d) {
    this.setState({
      disabled: true,
      showError: true,
    });
  } else {
    if(moment(d, 'YYYY-MM-DD').isValid()) {
      this.setState({
        disabled: false,
        showError: false,
      });
    } else {
      this.setState({
        showDateError: false,
        disabled: true,
        showError: true,
      });
    }
  }
  this.setState({
    date: d,
  });
  getCollectionDate(date);
};

handleSelectChanged = e => {
  const value = e.target.value;
  const { setCompletedBy } = this.props;
  if (value) {
    this.setState({ completedBy: value, showCompletedByError: false });
  } else {
    this.setState({ completedBy: '', showCompletedByError: true });
  }
  setCompletedBy(value);
};

handleSelectChangedPreferences = e => {
  const value = e.target.value;
  localStorage.setItem('preference', value);
  const { setPreferences, setCollectFromHome, changeBookingData } = this.props;
  changeBookingData(value);
  if (value === 'HomeCollection') {
    setCollectFromHome(true);
  } else {
    setCollectFromHome(false);
  }
  if (value) {
    this.setState({ preferences: value, showPreferencesError: false });
  } else {
    this.setState({ preferences: '', showPreferencesError: true });
  }
  setPreferences(value);
};

handleSubmit = e => {
  e.preventDefault();
  const { onSubmit } = this.props;
  onSubmit();
};

errorMessage = () => {
  const { showError } = this.state;
  if (showError) {
    return <div className={css.error}>Please enter a date in the future</div>;
  } else {
    return null;
  }
};

componentWillMount() {
  try {
    const showHomeCollection = localStorage.getItem('showHomeCollection'); 
    const userOutside = localStorage.getItem('newCategory');
    if (userOutside !== null && userOutside === 'true') {
       this.setState({
         hideOptions : false
       })
    }
    if (showHomeCollection === 'true') {
      this.setState({ showHomeCollection: true });
    }
  } catch (error) {
    console.log('[ERROR][COLLECTIONDATE][WILLMOUNT] =======',error);
  }
}

render() {
  const { buttonText, collectFromHome, bookingData, bookingDetails, currentListing } = this.props;
  const {
    noDateSelect,
    showDateError,
    preferencesError,
    showPreferencesError,
    showHomeCollection,
    hideOptions
  } = this.state;
  const serviceKey = JSON.parse(localStorage.getItem('project'));
  return (
    <div>
      <form onSubmit={this.handleSubmit}>
          <div className={css.formGroup}>
              <label for="start">Which service do you prefer?</label>
              {(hideOptions && showHomeCollection) &&
                <div style={{ display: 'flex' }}>
                <input
                  onChange={this.handleSelectChangedPreferences}
                  className={css.radioStyle}
                  type="radio"
                  name="radio"
                  value="HomeCollection"
                ></input>{' '}
                <div style={{ display: 'block' }}>
                  <label>Home Collection + Drop-off (+£8)</label>
                  <div style={{ fontSize: '14px' }}>Maker comes to your home, includes a fitting</div>
                </div>
              </div>
              }
            {hideOptions && _.get(currentListing, 'attributes.publicData.havingStudio') === true && (
              <div style={{ display: 'flex' }}>
                <input
                  onChange={this.handleSelectChangedPreferences}
                  className={css.radioStyle}
                  type="radio"
                  name="radio"
                  value="visit"
                ></input>{' '}
                <div style={{ display: 'block' }}>
                  <label>I will visit their studio (free)</label>
                  <div style={{ fontSize: '14px' }}>See studio location on profile, includes a fitting</div>
                </div>
              </div>
            )}

            <div style={{ display: 'flex' }}>
              <input
                onChange={this.handleSelectChangedPreferences}
                className={css.radioStyle}
                type="radio"
                name="radio"
                value="post"
              ></input>{' '}
              <div style={{ display: 'block' }}>
                <label>Via post (Royal Mail or Hermes)</label>
                <div style={{ fontSize: '14px' }}>postage fees will be added to your booking at the next stage</div>
              </div>
            </div>
            {showPreferencesError && <span className={css.error}>{preferencesError}</span>}
  
            {hideOptions && _.get(currentListing, 'attributes.publicData.inStore') === true && (
              <div style={{ display: 'flex' }}>
                <input
                  onChange={this.handleSelectChangedPreferences}
                  className={css.radioStyle}
                  type="radio"
                  name="radio"
                  value="inStore"
                ></input>{' '}
                <div style={{ display: 'block' }}>
                  <label>In-Store (free)</label>
                  <div style={{ fontSize: '14px' }}>
                    Maker at in-store station or pop out event
                  </div>
                </div>
              </div>
            )}
          </div> 
          <div className={css.formGroup1}>
            <label for="start">Collection/Drop-off</label>
           <div className={css.selectStyle}> 
            <select
              id="completedby"
              name="completedby"
              onChange={this.handleSelectChangedPreferences}
            >
              <option value="">Select delivery type</option>
              <option value="HomeCollection">Maker visits me at home (£8)</option>
              {hideOptions && _.get(currentListing, 'attributes.publicData.havingStudio') === true && 
                 <option value="visit">Visit Maker at their studio (free)</option>
              }
              {hideOptions && _.get(currentListing, 'attributes.publicData.inStore') === true && 
                <option value="inStore">Meet Maker at in-store event (free)</option>
              }
              <option value="post">Postage (postage fees not included)</option>
            </select>
          </div>
          </div>
        
        {this.errorMessage()}
        <div className={css.formGroupCalender}>
          <label for="start">On what day?</label>
          <input
            type="date"
            id="start"
            className={css.calenderMobileView}
            name="trip-start"
            value={this.state.date}
            min={moment().format('YYYY-MM-DD')}
            max="2025-12-31"
            onChange={this.handleChanged}
          ></input>
          {showDateError && <span className={css.error}>{noDateSelect}</span>}
        </div>
        <div className={css.formGroupGarments}>
          <label for="start">When do you need it by?</label>
          <select
            id="completedby"
            name="completedby"
            className={css.garmentMobileView}
            value={this.state.completedBy}
            onChange={this.handleSelectChanged}
          >
            <option value="flexible">I'm flexible</option>
            <option value="soon">As soon as possible</option>
          </select>
        </div>

        <br />
        {!_.isEmpty(bookingData) && (
          <>
            <h3 class={css.breakdownTitle}>Order Details</h3>
            <BookingBreakdown
              className={css.bookingBreakdown}
              userRole="customer"
              unitType={config.bookingUnitType}
              bookingData={bookingData}
              collectFromHome={collectFromHome}
              type={serviceKey}
            />
          </>
        )}
        <br />
        <button
          type="submit"
          className={css.dateSubmit}
          type="submit"
          disabled={!(this.state.preferences)  || this.state.showError || this.state.disabled }
        >
          {' '}
          {buttonText}
        </button>
      </form>
    </div>
  );
}
}

