import React from 'react';
import { bool } from 'prop-types';
import config from '../../../config';

import { propTypes } from '../../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemUnitPrice = props => {
  const { bookingData, collectFromHome } = props;
  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>Total cost</div>
        <div className={css.totalPrice}>£ {bookingData.totalPrice.toFixed(2)}</div>
      </div>
    </>
  );
};

export default LineItemUnitPrice;
